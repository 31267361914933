const nAgt = navigator.userAgent;
let browserName = navigator.appName;
let fullVersion = `${parseFloat(navigator.appVersion)}`;
let majorVersion = parseInt(navigator.appVersion, 10);
let nameOffset; let verOffset; let ix;

// In Opera, the true version is after "OPR" or after "Version"
// eslint-disable-next-line no-cond-assign,eqeqeq
if ((verOffset = nAgt.indexOf("OPR")) != -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 4);
    // eslint-disable-next-line no-cond-assign,eqeqeq
    if ((verOffset = nAgt.indexOf("Version")) != -1) fullVersion = nAgt.substring(verOffset + 8);
// eslint-disable-next-line brace-style
}
// In MS Edge, the true version is after "Edg" in userAgent
// eslint-disable-next-line no-cond-assign,eqeqeq
else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
    browserName = "Microsoft Edge";
    fullVersion = nAgt.substring(verOffset + 4);
// eslint-disable-next-line brace-style
}
// In MSIE, the true version is after "MSIE" in userAgent
// eslint-disable-next-line no-cond-assign,eqeqeq
else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
// eslint-disable-next-line brace-style
}
// In Chrome, the true version is after "Chrome"
// eslint-disable-next-line no-cond-assign,eqeqeq
else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
// eslint-disable-next-line brace-style
}
// In Safari, the true version is after "Safari" or after "Version"
// eslint-disable-next-line no-cond-assign,eqeqeq
else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    // eslint-disable-next-line no-cond-assign,eqeqeq
    if ((verOffset = nAgt.indexOf("Version")) != -1) fullVersion = nAgt.substring(verOffset + 8);
// eslint-disable-next-line brace-style
}
// In Firefox, the true version is after "Firefox"
// eslint-disable-next-line no-cond-assign,eqeqeq
else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
// eslint-disable-next-line brace-style
}
// In most other browsers, "name/version" is at the end of userAgent
// eslint-disable-next-line no-cond-assign
else if ((nameOffset = nAgt.lastIndexOf(' ') + 1)
    // eslint-disable-next-line no-cond-assign
    < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    // eslint-disable-next-line eqeqeq
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
    }
}
// trim the fullVersion string at semicolon/space if present
// eslint-disable-next-line eqeqeq,no-cond-assign
if ((ix = fullVersion.indexOf(";")) != -1) fullVersion = fullVersion.substring(0, ix);
// eslint-disable-next-line eqeqeq,no-cond-assign
if ((ix = fullVersion.indexOf(" ")) != -1) fullVersion = fullVersion.substring(0, ix);

majorVersion = parseInt(`${fullVersion}`, 10);
// eslint-disable-next-line no-restricted-globals
if (isNaN(majorVersion)) {
    fullVersion = `${parseFloat(navigator.appVersion)}`;
    majorVersion = parseInt(navigator.appVersion, 10);
}

const {appName} = navigator;

export default {
    browserName,
    fullVersion,
    majorVersion,
    appName,
};
